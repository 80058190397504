<template>
  <div class="pb-20">
    <div class="mb-5">
      <h2 class="text-2xl font-bold">Investments</h2>
    </div>

    <nav class="border border-b-2 border-blue-200 px-8 pt-1">
      <ul class="flex flex-wrap items-center w-full">
        <li
          class="-mb-2px pr-20 relative"
          v-for="(t, index) in tabs"
          :key="index"
        >
          <a
            href="#!"
            class="block border-b-2 cursor-pointer duration-300 font-bold pt-3 pb-4 relative text-blue-400 hover:text-gray-600 text-sm"
            :class="{
              'border-blue-500 text-blue-500 hover:text-blue-500':
                tab === t.name
            }"
            @click.prevent="setTab(t.name)"
          >
            <span class="mr-2">{{ t.title }}</span>
          </a>
          <span
            class="absolute h-3 w-1 border-r-2 border-blue-200 right-10 top-1/2 transform -translate-y-1/2"
          />
        </li>
      </ul>
    </nav>
    
    <component :is="tab" />
  </div>
</template>

<script>
export default {
  components: {
    'investments': () => import(/* webpackChunkName: 'invest-investments' */ './Investments'),
    'credit-builder': () => import(/* webpackChunkName: 'invest-credit-builder' */ './CreditBuilder'),
    'investment-actions': () => import(/* webpackChunkName: 'invest-investment-actions' */ './InvestmentActions'),
  },
  data() {
    return {
      tab: 'investments',
    };
  },
  computed: {
    tabs() {
      const tabs = [{
          name: 'investments',
          title: 'All Investments'
        }];
      if (this.canDo('credpal:can_view_secured_investments')) {
        tabs.push({
          name: 'credit-builder',
          title: 'Credit Builder'
        });
      }
      if (this.canDo('credpal:can_view_investment_actions')) {
        tabs.push({
          name: 'investment-actions',
          title: 'Investment Actions'
        });
      }
      return tabs;
    }
  },
  mounted() {
    this.tab = this.$route.query.tab || this.tab;
  },
  methods: {
    setTab(tab) {
      this.tab = tab;
      this.$router.push({
        ...this.$route,
        query: { ...this.$route.query, tab }
      });
    }
  }
};
</script>
